.sidebar_column {
    color: #0e204f;
    font-size: 15px;
    display: flex;
    align-items: center;
    margin: 5px 0px;
    cursor: pointer;
    font-weight: 600;
}

.sidebar_column:hover {
    font-weight: 600;
    color: #3c74ff;
}

.main_space {
    background-color: rgb(241, 241, 255);
    height: 95vh;
    overflow-y: auto;
}

/* Hide scrollbar for WebKit browsers (Chrome, Safari, etc.) */
.main_space::-webkit-scrollbar {
    width: 0;
    /* Hide scrollbar */
}

/* Optional: Customize scrollbar colors for Firefox */
.main_space {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
}

.sidebar_column_upper {
    font-size: 12px;
    font-weight: 700;
    color: #1e3a86;
    margin-bottom: 5px;
}

.sidebar_dashboard {
    border-right: 2px solid #e0e9ff;
    height: 95vh;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
    background-image: linear-gradient(90deg, rgb(195, 206, 255) 0%, rgb(255, 255, 255) 100%);
}

/* For Webkit-based browsers (like Chrome, Safari) */
.sidebar_dashboard::-webkit-scrollbar {
    width: 3px;
    /* Width of the scrollbar */
}

.sidebar_dashboard::-webkit-scrollbar-track {
    background: transparent;
    /* Track color */
}

.sidebar_dashboard::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    /* Scrollbar thumb color */
    border-radius: 3px;
    /* Thumb border radius */
}

.dashboard_container {
    margin-top: 38px;
}
.sidemenu_link {
    color: #0e204f;
    text-decoration: none;
}
.sidemenu_link:hover {
    color: #3c74ff;
}


/* Profile */
.profile_image_side {
    background-color: #ffffff;
}
.profilephoto_user {
    height: 120px;
    width: 140px;
    border-radius: 50%;
}
.user_name {
    color: #0e204f;
    margin-top: 7px;
    font-size: 18px;
}
.user_email {
    font-size: 15px;
    color: #4d6095;
    margin-top: -8px;
}
.edit_photo_profile {
    border: none;
    padding: 2px 10px;
    background-color: #2a4280;
    color: white;
    border-radius: 5px;
    font-size: 13px;
}
.details_title {
    font-size: 13px;
    font-weight: 500;
    color: #4f6db9;
    display: flex;
    align-content: center;
}
.details_desc {
    font-size: 15px;
    font-weight: 500;
    color: #0e204f;
}
.profile_deails_side {
    background-color: #ffffff;
}
.edit_option_profile {
    color: rgb(0, 17, 90);
    cursor: pointer;
}
.edit_option_profile:hover {
    color: rgb(49, 87, 255);
}
.onelayer_input {
    border: none;
    border-bottom: 1px solid #0e204f;
}

/* For TeamManage */
.add_member_button {
    padding: 5px 15px;
    border-radius: 5px;
    border: 2px solid #0e204f;
    color: #f6f9ff;
    font-weight: 600;
    background-color: #0e204f;
}
.add_member_button:hover {
    color: #0e204f;
    background-color: #f6f9ff;
}
.left_side_team_manage {
    display: flex;
    align-items: end;
    padding-bottom: 7px;
    color:rgb(0, 17, 90);
}
.team_heading_row {
    background-color: #ffffff;
    margin: 5px 0px;
    border-radius: 3px;
}
.team_details_cols {
    background-color: #ffffff;
    border-radius: 3px;

}
.td1k_manage_team {
    font-weight: 700;
    color: #1e3a86;
}
.td1v_manage_team {
    font-weight: 600;
    color: #455275;
}
.basic_details_heading_manage_team {
    font-weight: 800;
    padding: 8px 10px 0px 10px;
    font-size: 18px;
    color: #1e3a86;
}
.member_card_team {
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 20%, rgb(0, 135, 162) 100%);
    margin: 7px -2px;
    padding: 0px 10px;
    border-radius: 8px;
    display: flex;
    cursor: pointer;
}
.member_card_team2 {
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 20%, rgb(0, 135, 162) 100%);
    padding: 0px 10px;
    border-radius: 8px;
    display: flex;
    cursor: pointer;
}
.member_name_manage_team {
    color: #ffffff;
    font-weight: 600;
    font-size: 17px;
    margin-top: 4px;
}
.member_role_manage_team {
    color: #a0cdff;
    font-size: 12px;
    margin-top: -5px;
    margin-bottom: 4px;
}
.member_status_manage_team {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.member_status_active {
    color: #00ff00;
    font-size: 13px;
    margin-top: 2px;
}
.member_status_inactive {
    color:rgb(81, 81, 81);
    font-size: 13px;
    margin-top: 2px;
}
.member_status_online {
    color: #a5ffa5;
    font-size: 10px;
}
.member_status_offline {
    color:rgb(255, 255, 255);
    font-size: 10px;
}
.fix_height_row_manage_team {
    height: 250px;
    overflow-y: scroll;
}

/* For Webkit-based browsers (like Chrome, Safari) */
.fix_height_row_manage_team::-webkit-scrollbar {
    width: 3px;
    /* Width of the scrollbar */
}

.fix_height_row_manage_team::-webkit-scrollbar-track {
    background: transparent;
    /* Track color */
}

.fix_height_row_manage_team::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    /* Scrollbar thumb color */
    border-radius: 3px;
    /* Thumb border radius */
}

.modal_header_add_member {
    border-bottom: none;
}
.modal_footer_add_member {
    border-top: none;
}
.add_to_team_button {
    padding: 2px 8px;
    border: 2px solid #0e204f;
    background-color: #0e204f;
    color: #ffffff;
    border-radius: 5px;
}
.add_to_team_button:hover {
    background-color: #ffffff;
    color: #021136;
    font-weight: 600;
}
.cancel_team {
    padding: 2px 8px;
    border: 2px solid #c00722;
    background-color: #c00722;
    color: #ffffff;
    border-radius: 5px;
}
.cancel_team:hover {
    background-color: #ffffff;
    color: #c00722;
    font-weight: 600;
}
.modal_content_add_member {
    background: linear-gradient(90deg, rgb(194, 190, 255) 0%, rgb(214, 214, 255) 20%, rgb(186, 216, 255) 100%);
    border: none;
}
.modal_body_add_member {
    padding: 0px 28px;
}
.enter_mail_label {
    padding-bottom: 5px;
}
.add_member_input {
    width: 100%;
    color:#0e204f;
    font-weight: 500;
    margin-bottom: 10px;
    outline: none;
    border: none;
    margin-top: 5px;
}
.add_member_input_div {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    border: 2px solid #4f6db9;
    border-radius: 5px;
    padding: 4px 0px -3px 0px;
    height: 40px;
}
.search_add_member {
    margin-top: -4px;
    color:rgb(49, 87, 255);
    font-weight: 600;
    display: flex;
    justify-content: center;
}
.search_add_member:hover {
    color:rgb(90, 120, 255);
    margin-top: -2px;
}
.activity_time_team {
    font-size: 11px;
    font-weight: 600;
    margin-bottom: -2px;
}
.activity_details_team {
    font-size: 13px;
    font-weight: 500;
}
.activity_list {
    color: #0e204f;
    margin-bottom: 6px;
}
.activity_time_icon {
    margin-right: -10px;
    font-size: small;
}
.activity_details_total {
    background-color: #dfeeff;
    width: 95%;
    margin-left: 10px;
    padding: 5px 10px;
    border-radius: 5px;
}
.name_style_activity {
    color: #9500ff;
}
