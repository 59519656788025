.report_input {
    width: 100%;
    border: 2px solid #00095e;
    border-radius: 5px;
    height: 35px;
    outline: #c1c7ff;
    font-weight: 600;
    color: #7482ff;
}

.report_input::placeholder {
    color: #a8b0ff;
}

.report_input:focus {
    border: 2px solid #7482ff;
}

.input_label_report {
    font-size: 13px;
    font-weight: 600;
    color: #00095e;
}

.report_heading {
    text-align: center;
    font-weight: 600;
    color: #1d2148;
}

.autofill_div {
    max-height: 500px;
    border: 2px solid #c1c7ff;
    overflow-y: scroll;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* IE and Edge */
    padding-top: 10px;
    margin-top: -12px;
    border-radius: 5px;
}

.autofill_div::-webkit-scrollbar {
    display: none;
    /* Hide scrollbar for WebKit browsers */
}

.autofill_option {
    height: 40px;
    padding-left: 10px;
}

.get_report_button {
    padding: 4px 10px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 35px;
    align-self: flex-end;
    justify-content: center;
    background-color: #00095e;
    border: 2px solid #00095e;
    border-radius: 5px;
    color: white;
    font-weight: 600;
}

.get_report_button:hover {
    background-color: transparent;
    color: #00095e;
}

.show_result_heading {
    font-size: 13px;
    font-weight: 600;
    color: #00095e;
}

.showing_result {
    color: #4a5cf8;
}