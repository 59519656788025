.login_heading {
    font-weight: 700;
}

.login_frame {
    padding: 10px 100px 20px 100px;
}

.login_text {
    color: #6F6F6F;
    font-size: 14px;
    font-weight: 400;
}

.login_labels {
    color: #444444;
    font-weight: 600;
    font-size: 14px;
    font-family: "Noto Sans", sans-serif;
    padding-bottom: 5px;
}

.forgot_password_login {
    color: #E41F07;
    font-weight: 500;
    font-size: 14px;
    text-decoration: none;
    cursor: pointer;
    padding-top: 20px;
    text-align: end;
}

.input_row_login {
    padding-top: 20px;
}

.login_button {
    background-color: #094b8c;
    color: white;
    width: 100%;
    border: none;
    height: 42px;
    border-radius: 4px;
    margin-top: 15px;
    font-weight: 500;
}
.register_button {
    background-color: #094b8c;
    color: white;
    width: 100%;
    border: none;
    height: 42px;
    border-radius: 4px;
    margin-top: 15px;
    font-weight: 500;
}

.input_login {
    width: 100%;
    border: 1px solid #e0e0e0;
    color: #5b5b5b;
    outline: none;
    height: 42px;
    border-radius: 4px;
    background-color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    padding: 3px 40px 3px 10px;
    line-height: 3px;
    box-shadow: 0px 4px 4px 0px rgba(186, 186, 186, 0.251);
}

.new_login_text {
    color: #6F6F6F;
    font-size: 15px;
    font-weight: 400;
    padding-top: 20px;
}

.new_login_link {
    color: #01386f;
    font-weight: 500;
    font-size: 14px;
    text-decoration: none;
    cursor: pointer;
    padding-top: 20px;
}

.or_text {
    color: #6F6F6F;
    position: relative;
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    padding-top: 10px;
}

.social_link_button_login {
    width: 100%;
    height: 48px;
    border-radius: 8px;
    margin: 15px 5px 0px 5px;
    font-weight: 500;
    font-size: 20px;
    padding: 3px 10px;
    line-height: 3px;
    box-shadow: 0px 4px 4px 0px rgba(186, 186, 186, 0.251);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.facebook_login {
    background-color: #0092E4;
    color: white;
}
.apple_login {
    background-color: #000000;
    color: white;
    font-size: 24px;
}
.google_login {
    /* backgrung color with google color gradient */
    background-image: linear-gradient(90deg, #0768f9, #ff3b41);
    color: white;
}
.or_login_text {
    color: #444444;
    font-weight: 600;
    text-align: center;
    padding-top: 10px;
}
.logo_login_image {
    width: 60px;
    /* center the image */
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
}
.logo_login_text {
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    color: #1e5c86;
    padding-bottom: 30px;
    margin-top: -5px;
}
.mail_icon_login {
    text-align: end;
    padding-right: 10px;
    margin-top: -38px;
    color: #6F6F6F;
    font-size: 20px;
}
.password_icon {
    cursor: pointer;
}
.password_icon:hover {
    color: #003569;
}
.name_label_row {
    padding-bottom: 20px;
}
.terms_label {
    margin-top: -6px;
}
.go_to_home_link {
    color: #375776;
    text-decoration: none;
    font-weight: 700;
    text-align: end;
}
.resend_otp {
    display: flex;
    justify-content: center;
    padding-top: 20px;
}
.resend_otp_text {
    cursor: pointer;
}
.resend_otp_text:hover {
    color: #003569;
}

.login_side_image {
    margin-top: 50px;
    animation: moveUpDown 2s ease-in-out infinite alternate;
}

@keyframes moveUpDown {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-20px);
    }
}






/* media query */
@media only screen and (max-width: 767px) {
    .login_frame {
        padding: 20px 10px;
    }
    .login_description {
        display: none;
    }
}

/* for large screens */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .login_frame {
        padding: 20px 10px;
    }
}

/* for medium screens */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .login_frame {
        padding: 20px 100px;
    }
    .login_description {
        display: none;
    }
}