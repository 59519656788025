.followup_heading {
	color: #00095e;
	font-weight: 600;
}

.clearfix:after {
	clear: both;
	content: "";
	display: block;
	height: 0;
}

.container {
	font-family: 'Lato', sans-serif;
	width: 1000px;
	margin: 0 auto;
}

.wrapper {
	display: table-cell;
	height: 40px;
	vertical-align: middle;
}

.nav {
	margin-top: 40px;
}

.pull-right {
	float: right;
}

/* a,
a:active {
	color: #333;
	text-decoration: none;
}

a:hover {
	color: #999;
} */

/* Breadcrups CSS */

.arrow-steps .step {
	font-size: 14px;
	font-weight: 500;
	cursor: pointer;
	text-align: center;
	color: #666;
	margin: 0 3px;
	padding: 5px 10px 10px 30px;
	min-width: 180px;
	float: left;
	position: relative;
	background-color: #d9e3f7;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	transition: background-color 0.2s ease;
}

.arrow-steps .step:after,
.arrow-steps .step:before {
	content: " ";
	position: absolute;
	top: 0;
	right: -16px;
	width: 0;
	height: 0;
	border-top: 19px solid transparent;
	border-bottom: 17px solid transparent;
	border-left: 17px solid #d9e3f7;
	z-index: 2;
	transition: border-color 0.2s ease;
}

.arrow-steps .step:before {
	right: auto;
	left: 0;
	border-left: 17px solid #fff;
	z-index: 0;
}

.arrow-steps .step:first-child:before {
	border: none;
}

.arrow-steps .step:first-child {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}

.arrow-steps .step span {
	position: relative;
}

.arrow-steps .step span:before {
	opacity: 0;
	content: "✔";
	position: absolute;
	top: -2px;
	left: -20px;
}

.arrow-steps .step.done span:before {
	opacity: 1;
	-webkit-transition: opacity 0.3s ease 0.5s;
	-moz-transition: opacity 0.3s ease 0.5s;
	-ms-transition: opacity 0.3s ease 0.5s;
	transition: opacity 0.3s ease 0.5s;
}

.arrow-steps .step.current {
	color: #fff;
	background-color: #23468c;
}

.arrow-steps .step.current:after {
	border-left: 17px solid #23468c;
}

.info_text_followup {
	color: #23468c;
	margin-top: 10px;
	font-weight: 600;
	font-size: 14px;
}

.followup_button {
	color: white;
	border: 2px solid #23468c;
	background-color: #38538a;
	border-radius: 5px;
	font-size: 14px;
	height: 35px;
	font-weight: 600;
}

.followup_button:hover {
	background-color: transparent;
	color: #23468c;
}
.hover_color_sendMail {
	color: white;
	text-decoration: none;
}
.hover_color_sendMail:hover {
	background-color: transparent;
	color: #23468c;
}

.edit_button_followup {
	cursor: pointer;
}

.edit_button_followup:hover {
	color: #84acfd;
}

.mail_history_heading {
	color: #23468c;
	font-weight: 600;
	font-size: 15px;
}

.mail_subject {
	font-weight: 600;
	color: #23468c;
	font-size: 12px;
}

.mail_date {
	font-weight: 600;
	color: #23468c;
	font-size: 10px;
}

.history_row {
	box-shadow: 1px 1px 10px #00095e;
	border-radius: 5px;
	padding-top: 7px;
}

.upper_history_row {
	padding-left: 7px;
	padding-right: 7px;
}

.normal_activity_message {
	color: #485f8d;
}

.edit_input_followup {
	background-color: transparent;
	color: #23468c;
	border: none;
	border-bottom: 2px solid rgb(0, 106, 255);
	outline: none;
}

.modal_header {
	border: none;
}

.modal_footer {
	border: none;
}

.stylish_modal {
	box-shadow: 2px 2px 10px #00095e;
}

.followup_input {
	border: 2px solid #00095e;
	border-radius: 5px;
	height: 35px;
	outline: #8cb2ff;
	color: #23468c;
	font-weight: 600;
}

.close_button_followup {
	border: none;
	background-color: #616161;
	padding: 2px 13px;
	border-radius: 5px;
	color: white;
	font-weight: 600;
}

.submit_button_followup {
	border: none;
	background-color: #00095e;
	padding: 2px 13px;
	border-radius: 5px;
	color: white;
	font-weight: 600;
}

.input_label_followup {
	color: #00095e;
	margin-left: -12px;
}

.send_mail_heading {
	text-align: center;
	color: #000213;
	font-weight: 600;
}
.send_mail_subject {
	margin-top: -20px;
}
.jodit-wysiwyg {
	height: 500px;
}