.view_details_heading {
    font-weight: 600;
    color: #00095e;
}
.css-i4bv87-MuiSvgIcon-root {
    color:#00095e !important;
}
.goback_icon {
    font-size: 30px;
    float: left;
    cursor: pointer;
    color: #00095e;
}
.goback_icon:hover {
    color: #4180ff;
}