.link_delink {
    text-decoration: none;
    width: 160px;
}

.brand_name_style {
    font-weight: 700;
}

.custom_sidebar {
    padding: 2px 5px;
    width: 13%;
    margin-top: -15px;
}

.custom_header_navbar {
    padding-bottom: -10px;
    padding-top: -10px;
    height: 45px;
    box-shadow: 2px 2px 10px #00095e;
}

.sub_link_menu {
    text-decoration: none;
}

.nav-item {
    text-decoration: none;
}
.sidebar-nav .nav-link {
    color: #001944;
    margin-top: 5px;
    padding: 7px;
    background-color: white;
    box-shadow: 2px 2px 10px #00095e;
}

.custom_icon_sidebar:hover {
    color:inherit !important;
}
.sidebar_sublink {
    text-decoration: none;
}

.profile_dropdown {
    border: 1px solid #00095e;
    box-shadow: 3px 3px 10px #00095e;
}
.profile_image_header {
    box-shadow: 3px 3px 10px #00095e;
}

.custom_main {
    margin-top: 10px;
}
.profile_name {
    color: #00095e !important;
}
.dropdown-divider {
    background-color: #00095e !important;
}


