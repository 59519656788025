.generate_leads_heading {
    color: #1c246c;
    font-weight: 600;
}
.generate_leads_subheading {
    color: #3343ce;
    font-weight: 600;
}
.keyword_input {
    width: 250px;
    height: 38px;
    border: 2px solid #3846c1;
    border-radius: 5px;
    outline: #3846c1;
    color: #3846c1;
    font-weight: 500;
    padding-left: 10px;
}
.keyword_input::placeholder {
    color: #7784ff;
}
.place_input {
    width: 250px;
    height: 38px;
    border: 2px solid #3846c1;
    border-radius: 5px;
    outline: #3846c1;
    color: #3846c1;
    font-weight: 500;
    padding-left: 10px;
}
.place_input::placeholder {
    color: #7784ff;
}
.input_div {
    display: flex;
    justify-content: end;
}
.generate_lead_button {
    color: white;
    width: 250px;
    height: 38px;
    border: 2px solid #3846c1;
    background-color: #3846c1;
    border-radius: 5px;
    font-weight: 600;
}
.generate_lead_button:hover {
    background-color: transparent;
    color: #3846c1;
    font-weight: 700;
}

.extract_data {
    text-align: center;
    color:#a30000;
    font-size: 18px;
    font-weight: 600;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.scrap_data {
    text-align: center;
    color:rgb(163, 130, 0);
    font-size: 18px;
    font-weight: 600;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
}



@media (max-width: 767px) {
    .keyword_input {
        width: 100%;
    }
    .place_input {
        width: 100%;
        margin-top: 10px;
    }
}