.all_results_heading {
    color: #00095e;
    font-weight: 600;
}

.result_items {
    color: #00095e;
    font-size: 13px;
    font-weight: 600;
}
.result_items2 {
    color: #00095e;
    font-size: 15px;
    font-weight: 600;
    display: flex;
    align-items: end;
}

.result_row {
    box-shadow: 2px 2px 10px #00095e;
    align-items: center;
    padding: 7px 5px 2px 5px;
    border-radius: 5px;
}

.view_button {
    font-size: 12px;
    padding: 5px 10px;
    background-color: #232b71;
    border: none;
    border-radius: 5px;
    color: white;
}

.delete_button {
    font-size: 12px;
    padding: 5px 10px;
    background-color: #da0202;
    border: none;
    border-radius: 5px;
    color: white;
}

.filter_inputs_result {
    height: 35px;
    border: 2px solid #00095e;
    border-radius: 5px;
    color: #00095e;
    margin-left: 10px;
    padding: 3px 5px;
}
.filter_inputs_result::placeholder {
    color:#6e78ca;
}