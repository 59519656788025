.right_header {
    display: flex;
    justify-content: end;
}

.nav_elements {
    padding: 5px 25px;
    font-weight: 500;
    color: #091688;
    cursor: pointer;
    text-decoration: none;
}

.nav_elements:hover {
    color: #3083ff;
}

.left_header {
    font-size: 25px;
    font-weight: 700;
    font-style: bold;
    color: #095188;
}

.full_navbar {
    display: flex;
    align-items: center;
}

.start_free_trial_button {
    border: none;
    border-radius: 20px;
    background-image: linear-gradient(90deg, #0768f9, #ff3b41);
    color: white;
    padding: 5px 10px;
    font-weight: 500;
}

.menu_button_div {
    color: rgb(30, 57, 130);
    font-size: 30px;
    display: none;
}

.mobile_navigation_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 25px;
}

.navbar_container {
    padding: 0px 10px;
}

.profile_navbar {
    height: 30px;
    border-radius: 50px;
}

.name_navbar {
    color: rgb(30, 57, 130);
    font-weight: 500;
}

.profile_tab_container {
    border-radius: 50px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.right_header_left {
    font-size: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    color:rgb(30, 57, 130);
    cursor: pointer;
}

.right_header_right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    color:rgb(30, 57, 130);
}
.profile_picture_upperbar {
    height: 30px;
    border-radius: 50px;
}
.profile_name_upperbar {
    font-size: 15px;
    padding-top: 10px;
    color:rgb(30, 57, 130);
    font-weight: 550;
}
.shadow_header {
    background-image: linear-gradient(180deg, rgb(195, 206, 255) 0%, rgb(195, 216, 255) 100%);
}
.upperbarLogo {
    height: 35px;
}



/* For medium screens (e.g., tablets) */
@media screen and (max-width: 992px) {
    .right_header {
        display: none;
    }

    .menu_button_div {
        display: flex;
        justify-content: end;
    }
}

/* For small screens (e.g., smartphones) */
@media screen and (max-width: 576px) {}

/* For Hero Section */
.sidehero_image {
    width: 100%;
}

.hero_heading1 {
    font-weight: 800;
    font-size: 54px;
    text-align: center;
    color: #080f45;
}

.hero_heading2 {
    text-align: center;
    font-size: 18px;
    color: #465672;
}

.hero_row {
    --bs-gutter-x: 0px;
}

.hero_headings_all {
    display: flex;
    flex-direction: column;
    align-self: center;
    --bs-gutter-x: 0;
}

.heading_2nd_hero {
    text-align: center;
    font-style: italic;
    font-weight: 600;
    color: rgb(73, 121, 255);
}

.sidehero_image {
    animation: moveUpDown 2s ease-in-out infinite alternate;
}

@keyframes moveUpDown {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-20px);
    }
}

.button_row_hero {
    display: flex;
    justify-content: center;
    z-index: 100;
}

.hero_button_normal {
    width: 22%;
    padding: 8px 10px;
    margin: 30px 20px 5px 20px;
    border-radius: 20px;
}

.red_blue_hero_button {
    border: none;
    background-image: linear-gradient(90deg, #0768f9, #ff3b41);
    color: white;
    font-weight: 500;
}

.green_blue_hero_button {
    border: none;
    background-image: linear-gradient(90deg, #0768f9, #3bff9d);
    color: white;
    font-weight: 500;
}

/* For small screens (e.g., smartphones) */
@media screen and (max-width: 576px) {
    .hero_button_normal {
        width: 40%;
        margin: 30px 10px 30px 10px;
    }

    .hero_heading1 {
        font-size: 48px;
    }
}



/* For Services */
.service_container {
    background-color: #080f45;
}

.services_heading {
    display: flex;
    justify-content: center;
    font-size: 35px;
    font-weight: 700;
    color: #ffffff;
}

.service_card {
    border: none;
    border-radius: 10px;
    width: 99%;
    padding: 10px;
    display: flex;
    justify-content: center;
    box-shadow: #0768f9 0px 0px 4px;
    background-color: #ffffff;
}

.services_subheading {
    font-weight: 500;
    color: #9dc4ff;
    font-size: 17px;
    padding: 0px 20px;
    margin-bottom: 50px;
}

.service_card_heading {
    text-align: center;
    font-weight: 600;
    color: rgb(4, 72, 244);
}

.service_button {
    border: none;
    width: 95%;
    padding: 5px;
    border-radius: 8px;
    background-image: linear-gradient(90deg, rgb(0, 106, 255) 0%, rgb(157, 0, 255) 100%);
    color: #ffffff;
    font-weight: 600;
    margin: 15px 0px 15px 0px;
}

/* .service_button:hover {
    border: 2px solid rgb(13, 36, 98);
    background-color: rgb(13, 36, 98);
    color: white;
} */
.service_card_desc {
    color: rgb(43, 61, 150);
}

.service_logo {
    font-size: 80px;
    color: rgb(4, 72, 244);
    padding: 20px 0px;
}

/* What we do */
.moving_photo_landing_page {
    height: 480px;
    -webkit-border-radius: 51% 49% 77% 23%/65% 50% 50% 35%;
    -moz-border-radius: 51% 49% 77% 23%/65% 50% 50% 35%;
    border-radius: 51% 49% 77% 23%/65% 50% 50% 35%;
    -webkit-animation: img-border 5s linear infinite alternate;
    -moz-animation: img-border 5s linear infinite alternate;
    -o-animation: img-border 5s linear infinite alternate;
    animation: img-border 5s linear infinite alternate;
    transition: all 0.3s ease-out 0s;
}

@keyframes img-border {
    0% {
        -webkit-border-radius: 51% 49% 77% 23%/65% 50% 50% 35%;
        -moz-border-radius: 51% 49% 77% 23%/65% 50% 50% 35%;
        border-radius: 51% 49% 77% 23%/65% 50% 50% 35%;
    }

    100% {
        -webkit-border-radius: 30% 70% 28% 72%/53% 69% 31% 47%;
        -moz-border-radius: 30% 70% 28% 72%/53% 69% 31% 47%;
        border-radius: 30% 70% 28% 72%/53% 69% 31% 47%;
    }
}

.why_choose_us {
    background-color: rgb(138, 185, 255);
    color: rgb(0, 30, 255);
    padding: 2px 5px;
}

.what_we_do_heading {
    font-weight: 600;
    padding-right: 100px;
}

.tpbanner__sub-title span {
    position: absolute;
    left: 3%;
    right: 0;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-55%);
    font-weight: 600;
    font-size: 16px;
    color: #4260FF;
    display: inline-block;
}

.tpbanner__sub-title {
    position: relative;
    display: inline-block;
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-25 {
    margin-bottom: 25px;
}

.what_we_do_desc {
    color: rgb(113, 112, 112);
    margin-bottom: 40px;
    font-size: 13px;
}

.tpchoose-text span {
    display: block;
}

.tpchoose-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: #333333;
    display: inline-block;
}

.tparrow-right {
    position: relative;
    display: inline-block;
    font-size: 30px;
    color: rgb(119, 119, 119);
}

.tparrow-right i {
    display: inline-block;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 0px;
    background-color: #8a8a8d;
    transition: all 0.25s;
}

.tpchoose-text i {
    display: block;
    color: #b2b2b2;
}

.card_what_we_do {
    border: 1px solid rgb(230, 230, 230);
    border-radius: 10px;
    padding: 30px 25px;
    cursor: pointer;
}

.card_what_we_do:hover {
    box-shadow: #cbcbcb 0px 0px 15px;
}

.details_part_whatwedo {
    padding-right: 150px;
}

.photo_part_div {
    /* Adjust the height as needed */
    /* Adjust the background size */
    background-position: center;
    background-size: 90%;
}


/* Contact Section */
.contact_name_heading {
    font-size: 14px;
    color: white;
    font-weight: 600;
}

.contact_row {
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact_ready {
    color: white;
    font-weight: 600;
}

.info_section {
    display: flex;
    flex-direction: row;
}

.social_icons {
    border: 1px solid rgb(218, 218, 218);
    margin-right: 5px;
    padding: 8px 11px;
    border-radius: 200px;
    cursor: pointer;
}

.social_icons:hover {
    border: 1px solid rgb(118, 154, 255);
    color: rgb(65, 114, 247);
}

.input_contact_form {
    width: 100%;
    margin-bottom: 15px;
    height: 45px;
    border-radius: 30px;
    border: none;
    background-color: #ececff;
    padding: 10px 20px;
    outline: none;
    color: rgb(30, 57, 130);
}

.input_contact_form::placeholder {
    color: rgb(118, 154, 255);
}

.send_message_button_contact {
    height: 45px;
    border-radius: 30px;
    border: none;
    background-color: #589bff;
    color: white;
    font-size: large;
    font-weight: 600;
}

.input_contact_form_large {
    height: 170px;
    margin-bottom: 30px;
    border-radius: 15px;
    border: none;
    padding: 10px 20px;
    background-color: #ececff;
    outline: none;
    color: rgb(30, 57, 130);
}

.input_contact_form_large::placeholder {
    color: rgb(118, 154, 255);
}

.contact_div {
    border: 2px solid #3c8aff;
    padding: 40px 40px;
    border-radius: 15px;
}



/* //For Footer */

.footer_container {
    padding: 20px 0px;
}

.footer_row {
    display: flex;
    justify-content: center;
    align-items: center;
}

.copyrights_footer {
    /* background-color: rgb(228, 237, 255); */
    padding: 15px 0px;
    border-radius: 30px;
    color: rgb(160, 195, 255);
    border: 2px solid #3c8aff;
}



/* For Pricing section */
@import url(https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600);

.snip1404 {
    font-family: 'Source Sans Pro', Arial, sans-serif;
    color: #ffffff;
    text-align: left;
    font-size: 18px;
    width: 100%;
    max-width: 1200px;
    margin: 50px 10px;
}

.snip1404 img {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    z-index: -1;
}

.snip1404 .plan {
    margin: 0;
    width: 25%;
    position: relative;
    float: left;
    overflow: hidden;
    border: 3px solid #161d2a;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    background-color: #302e78;
}

.snip1404 .plan:hover i,
.snip1404 .plan.hover i {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
}

.snip1404 .plan:first-of-type {
    border-radius: 8px 0 0 8px;
}

.snip1404 .plan:last-of-type {
    border-radius: 0 8px 8px 0;
}

.snip1404 * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
}

.snip1404 header {
    background-color: #302e78;
    color: #ffffff;
}

.snip1404 .plan-title {
    background-color: rgba(0, 0, 0, 0.5);
    position: relative;
    margin: 0;
    padding: 20px 20px 0;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.snip1404 .plan-title:after {
    position: absolute;
    content: '';
    top: 100%;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 40px 300px 0 0;
    border-color: rgba(0, 0, 0, 0.5) transparent transparent;
}

.snip1404 .plan-cost {
    padding: 40px 20px 10px;
    text-align: right;
}

.snip1404 .plan-price {
    font-weight: 600;
    font-size: 3em;
}

.snip1404 .plan-type {
    opacity: 0.8;
    font-size: 0.7em;
    text-transform: uppercase;
}

.snip1404 .plan-features {
    padding: 0 0 20px;
    margin: 0;
    list-style: outside none none;
}

.snip1404 .plan-features li {
    padding: 8px 5%;
}

.snip1404 .plan-features i {
    margin-right: 8px;
    color: rgba(0, 0, 0, 0.5);
}

.snip1404 .plan-select {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    padding: 20px;
    text-align: center;
}

.snip1404 .plan-select a {
    background-color: #040073;
    color: #ffffff;
    text-decoration: none;
    padding: 12px 20px;
    font-size: 0.75em;
    font-weight: 600;
    border-radius: 20px;
    text-transform: uppercase;
    letter-spacing: 4px;
    display: inline-block;
}

.snip1404 .plan-select a:hover {
    background-color: rgb(64, 60, 164);
}

.snip1404 .plan:hover {
    margin-top: -10px;
    border-color: #15181e;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.4);
    z-index: 1;
    border-radius: 8px;
    cursor: pointer;
}

.snip1404 .plan .plan-select {
    padding: 30px 20px;
}

@media only screen and (max-width: 767px) {
    .snip1404 .plan {
        width: 50%;
    }

    .snip1404 .plan-title,
    .snip1404 .plan-select a {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    .snip1404 .plan-select,
    .snip1404 .featured .plan-select {
        padding: 20px;
    }

    .snip1404 .featured {
        margin-top: 0;
    }
}

@media only screen and (max-width: 440px) {
    .snip1404 .plan {
        width: 100%;
        border-radius: 2% !important;
        margin-bottom: 10px;
    }
}

.pricing_header {
    font-size: 40px;
    font-weight: 600;
    color: #00337e;
}

.talk_to_us_button {
    border: 1px solid #3083ff;
    padding: 2px 6px;
    border-radius: 5px;
    cursor: pointer;
    color: #3083ff;
}

.talk_to_us_button:hover {
    background-color: #3083ff;
    color: white;
}

.talk_to_us_button2 {
    width: 120px;
    color: rgb(64, 60, 164);
    border-radius: 5px;
    border: 2px solid rgb(64, 60, 164);
    font-weight: 600;
}

.unique_case_section {
    background-color: rgb(52, 48, 142);
    color: white;
    border-radius: 5px;
}