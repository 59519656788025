.jodit-workplace {
    min-height: 500px !important;
}

.new_mail_buttons_cancel {
    width: 100%;
    padding: 6px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    border: 2px solid rgb(255, 69, 69);
    color: rgb(255, 69, 69);
    background-color: white;
}
.new_mail_buttons_cancel:hover {
    background-color: rgb(255, 69, 69);
    color: white;
}
.new_mail_buttons_send {
    width: 100%;
    padding: 6px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    border: 2px solid rgb(18, 105, 59);
    color: rgb(18, 105, 59);
    background-color: white;
}
.new_mail_buttons_send:hover {
    background-color: rgb(18, 105, 59);
    color: white;
}
.new_mail_buttons_draft {
    width: 100%;
    padding: 6px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    border: 2px solid rgb(12, 83, 95);
    color: rgb(12, 83, 95);
    background-color: white;
}
.new_mail_buttons_draft:hover {
    background-color: rgb(12, 83, 95);
    color: white;
}
.new_mail_buttons_draft_saved {
    width: 100%;
    padding: 6px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    border: 2px solid rgb(136, 140, 141);
    color: rgb(136, 140, 141);
    background-color: white;
}

.mail_from_new_mail {
    font-size: 15px;
    font-weight: 500;
    color: rgb(39, 40, 83);
}

.mail_from_new_mail_span {
    color: rgb(32, 109, 191);
}