.mail-item {
    border-bottom: 1px solid #dee2e6;
    padding: 10px;
}
.mail-item:hover {
    background-color: #f8f8fa;
}
.subject {
    font-weight: 500;
    color: #00095e;
}
.content {
    color: #3a3d57;
}
.time {
    font-weight: 500;
    color: #00095e;
    text-align: right;
}
.star, .tick {
    cursor: pointer;
}
.star-marked {
    color: gold;
}

.mail-item, .header-row {
    border-bottom: 1px solid #dee2e6;
    padding: 10px;
}
.mail-item:hover {
    background-color: #9fcfff;
    cursor: pointer;
}
.subject {
    font-weight: bold;
}
.content {
    color: #2c4256;
    font-weight: 500;
}
.time {
    color: #435361;
    text-align: right;
    font-size: 13px;
}
.star, .tick, .reload, .arrow {
    cursor: pointer;
}
.reload:hover {
    color: #4f7e9b;
}
.star-marked {
    color: gold;
}
.pagination-result {
    text-align: center;
}
.pagination-info {
    text-align: center;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    padding: 5px;
}
.new_mail_button_send_mail {
    width: 100%;
    height: 36px;
    border-radius: 4px;
    border: 1px solid #dee2e6;
    background-color: #6385cd;
    font-weight: 500;
    color: #ffffff;
}
.event_send_mail_opened {
    font-size: 10px;
    color: #97ffe1;
    border: 1px solid #256a56;
    border-radius: 5px;
    background-color: #256a56;
    padding: 1px 3px;
    margin-left: 5px;
}
.event_send_mail_delivered {
    font-size: 10px;
    color: #c3f4ff;
    border: 1px solid #147d94;
    border-radius: 5px;
    background-color: #147d94;
    padding: 1px 3px;
    margin-left: 5px;
}
.event_send_mail_clicks {
    font-size: 10px;
    color: #c3f4ff;
    border: 1px solid #948714;
    border-radius: 5px;
    background-color: #948714;
    padding: 1px 3px;
    margin-left: 5px;
}
.event_send_mail_loadedByProxy {
    font-size: 10px;
    color: #c3f4ff;
    border: 1px solid #14943c;
    border-radius: 5px;
    background-color: #14943c;
    padding: 1px 3px;
    margin-left: 5px;
}
.tick-all {
    width: 18px;
    height: 16px;
    margin: 0; /* Reset any default margin */
    cursor: pointer;
    accent-color: #007bff; /* Primary color for checkbox */
}
.tick-all-container {
    display: flex;
    align-items: center;
}
.date_input_send_mail {
    width: 100%;
    height: 35px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding: 0 10px;
    font-size: 14px;
}
.delete_draft_icon:hover {
    color: #5e4646;
}
.edit_draft_button {
    color: #3a3d57;
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding: 0 10px;
    font-size: 14px;
}